import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context as UserContext } from "../contexts/UserContext";
import { useCookies } from "react-cookie";
import { config } from "../urls";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Chip from "@material-ui/core/Chip";
import Check from "@material-ui/icons/Check";
import Block from "@material-ui/icons/Block";
import Add from "@material-ui/icons/Add";
import Sync from "@material-ui/icons/Sync";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    marginTop: 70,
    [theme.breakpoints.up("md")]: {
      marginLeft: 280,
    },
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function Admin() {
  const { state: user } = useContext(UserContext);
  const [cookie, setCookie] = useCookies();
  const [domains, setDomains] = useState([]);
  const [entrou, setEntrou] = useState(true);
  const [input, setInput] = useState("");
  const [tipoAcesso, setTipoAcesso] = useState("APPROVED");
  const [atualizar, setAtualizar] = useState(false);
  const [processando, setProcessando] = useState(false);
  const [errado, setErrado] = useState(false);
  const [dialog, setDialog] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user.acesso !== "SUPER_ADMIN" && user.acesso !== "ADMIN") {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    if (processando === false || atualizar === true) {
      axios
        .get(config.API_URL + "/api/domains", {
          headers: { Authorization: "Bearer " + cookie.token },
        })
        .then((response) => {
          setDomains(response.data);
          setAtualizar(false);
          setEntrou(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [processando, atualizar]);

  const customChip = (value) => {
    if (value === "APPROVED") {
      return (
        <Chip
          variant="outlined"
          style={{
            color: "#04b51e",
            borderColor: "#04b51e",
          }}
          label="Aprovado"
        />
      );
    }
    if (value === "BLOCKED") {
      return (
        <Chip
          variant="outlined"
          style={{
            color: "#d62a1e",
            borderColor: "#d62a1e",
          }}
          label="Bloqueado"
        />
      );
    }
  };

  const options = {
    filterType: "checkbox",
    sortOrder: { name: "domain", direction: "asc" },
    responsive: "simple",
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 30, 50, 100],
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Atualizar">
            <IconButton
              onClick={() => {
                setEntrou(true);
                setAtualizar(true);
              }}
            >
              <Sync className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Adicionar Domínio">
            <IconButton onClick={() => setDialog(true)}>
              <Add className={classes.icon} />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      let domains = [];
      for (let i = 0; i < selectedRows.data.length; i++) {
        let index = selectedRows.data[i].index;
        domains.push(displayData[index].data[0]);
      }
      return (
        <div style={{ display: "flex", marginRight: 10 }}>
          <Tooltip title="Aprovar Domínios">
            <IconButton
              onClick={() => {
                setProcessando(true);
                axios
                  .post(
                    config.API_URL + "/api/domains",
                    { domains: domains, acesso: "APPROVED" },
                    { headers: { Authorization: "Bearer " + cookie.token } }
                  )
                  .then((response) => {
                    setSelectedRows([]);
                    setProcessando(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <Check style={{ color: "#04b51e" }} />
            </IconButton>
          </Tooltip>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "lightgrey",
              marginRight: 10,
              marginLeft: 10,
            }}
          ></div>
          <Tooltip title="Bloquear Domínios">
            <IconButton
              onClick={() => {
                setProcessando(true);
                axios
                  .post(
                    config.API_URL + "/api/domains",
                    { domains: domains, acesso: "BLOCKED" },
                    { headers: { Authorization: "Bearer " + cookie.token } }
                  )
                  .then((response) => {
                    setSelectedRows([]);
                    setProcessando(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <Block style={{ color: "#d62a1e" }} />
            </IconButton>
          </Tooltip>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "lightgrey",
              marginRight: 10,
              marginLeft: 10,
            }}
          ></div>
          <Tooltip title="Remover Domínios">
            <IconButton
              onClick={() => {
                setProcessando(true);
                axios
                  .delete(config.API_URL + "/api/domains", {
                    data: { domains: domains },
                    headers: { Authorization: "Bearer " + cookie.token },
                  })
                  .then((response) => {
                    setSelectedRows([]);
                    setProcessando(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
    setFilterChipProps: (colIndex, colName, filterValue) => {
      if (filterValue === "APPROVED") {
        return {
          variant: "outlined",
          style: {
            color: "#04b51e",
            borderColor: "#04b51e",
            marginTop: 12,
            marginBottom: 10,
          },
          label: "Aprovado",
        };
      }
      if (filterValue === "BLOCKED") {
        return {
          variant: "outlined",
          style: {
            color: "#d62a1e",
            borderColor: "#d62a1e",
            marginTop: 12,
            marginBottom: 10,
          },
          label: "Bloqueado",
        };
      }
    },
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro encontrado",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Limpar",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionadas",
        delete: "Remover",
        deleteAria: "Remover Linhas Selecionadas",
      },
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    setRowProps: () => {
      return {
        selected: false,
      };
    },
  };

  const columns = [
    {
      name: "domain",
      label: "Domínio",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "acesso",
      label: "Acesso",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return customChip(value);
        },
        filterOptions: {
          renderValue: (value) => {
            return customChip(value);
          },
        },
      },
    },
    {
      name: "lastChange",
      label: "Última Mudança",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return new Date(value).toLocaleString();
        },
        filter: false,
      },
    },
  ];

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 500 }}
        open={processando || entrou}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <MUIDataTable
        title={"Domínios"}
        columns={columns}
        data={domains}
        options={options}
      />
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(false);
        }}
      >
        <DialogTitle>Adicionar domínio</DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <DialogContentText>Escolha o tipo:</DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Radio
              onClick={() => setTipoAcesso("APPROVED")}
              checked={tipoAcesso === "APPROVED"}
            />
            <Chip
              variant="outlined"
              style={{
                color: "#04b51e",
                borderColor: "#04b51e",
              }}
              label="Aprovado"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <Radio
              onClick={() => setTipoAcesso("BLOCKED")}
              checked={tipoAcesso === "BLOCKED"}
            />
            <Chip
              variant="outlined"
              style={{
                color: "#d62a1e",
                borderColor: "#d62a1e",
              }}
              label="Bloqueado"
            />
          </div>
          <DialogContentText>Digite o domínio:</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            label="Domínio"
            value={input}
            error={errado}
            onChange={(event) => {
              let teste = /^(((([a-z0-9]+[\.\-])*[a-z0-9]+)+\.[a-z0-9]+)+)$/.exec(
                event.target.value
              );
              if (teste === null) {
                setErrado(true);
              } else if (teste !== null && teste.index !== 0) {
                setErrado(true);
              } else {
                setErrado(false);
              }
              setInput(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Cancelar</Button>
          <Button
            disabled={input === "" || errado === true ? true : false}
            onClick={() => {
              setDialog(false);
              setProcessando(true);
              axios
                .post(
                  config.API_URL + "/api/domains",
                  { domains: [input], acesso: tipoAcesso },
                  { headers: { Authorization: "Bearer " + cookie.token } }
                )
                .then((response) => {
                  setProcessando(false);
                })
                .catch((error) => {
                  console.log(error);
                });
              setInput("");
            }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
