import createDataContext from "./createDataContext";

const socketReducer = (state, action) => {
  switch (action.type) {
    case "setMensagem":
      return { ...state, mensagem: action.payload };
    case "setProcessando":
      return { ...state, processando: action.payload };
    case "setSalaReplicar":
      return { ...state, salaReplicar: action.payload };
    default:
      return state;
  }
};

const setMensagem = (dispatch) => {
  return (mensagem) => {
    dispatch({ type: "setMensagem", payload: mensagem });
  };
};

const setProcessando = (dispatch) => {
  return (processando) => {
    dispatch({ type: "setProcessando", payload: processando });
  };
};

const setSalaReplicar = (dispatch) => {
  return (salaReplicar) => {
    dispatch({ type: "setSalaReplicar", payload: salaReplicar });
  };
};

export const { Context, Provider } = createDataContext(
  socketReducer,
  { setMensagem, setProcessando, setSalaReplicar },
  { salaReplicar: "", socketId: "", mensagem: "", processando: "" }
);
