import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Home from "@material-ui/icons/Home";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import Publish from "@material-ui/icons/Publish";
import Exit from "@material-ui/icons/ExitToApp";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Security from "@material-ui/icons/Security";
import Button from "@material-ui/core/Button";
import Equalizer from "@material-ui/icons/Equalizer";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import AccountTree from "@material-ui/icons/AccountTree";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Help from "@material-ui/icons/Help";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { Context as UserContext } from "../contexts/UserContext";
import { Context as DrawerContext } from "../contexts/DrawerContext";
import { Context as PermissaoContext } from "../contexts/PermissaoContext";
import GetEdu from "../images/GetEdu.png";
import FRM from "../images//FRM.png";
import LoginGoogle from "../images/login_google.png";
import GoogleLogo from "../images/google-logo.png";
import Typography from "@material-ui/core/Typography";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
  },
  login: {
    color: "green",
  },
}));

export default function ClippedDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const { state: user } = useContext(UserContext);
  const { state: drawerToggle, setDrawerToggle } = useContext(DrawerContext);
  const { state: permissao } = useContext(PermissaoContext);
  const [collapse, setCollapse] = useState(false);

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Toolbar style={{ height: 70 }} />
      {user !== "" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            style={{ marginTop: 15, marginBottom: 10, height: 50, width: 50 }}
            alt={user.name}
            src={user.photo}
          />
          <Typography variant="subtitle1">{user.name}</Typography>
          <Typography variant="caption">({user.email})</Typography>
          {user.acesso === "SUPER_ADMIN" ? (
            <Chip
              variant="outlined"
              style={{
                marginTop: 10,
                color: "black",
                borderColor: "black",
              }}
              label="Super Administrador"
            />
          ) : null}
          {user.acesso === "ADMIN" ? (
            <Chip
              variant="outlined"
              style={{
                marginTop: 10,
                color: "#6e6b6a",
                borderColor: "#6e6b6a",
              }}
              label="Administrador"
            />
          ) : null}
          {user.acesso === "PENDING" ? (
            <Chip
              variant="outlined"
              style={{
                marginTop: 10,
                color: "#fcbe03",
                borderColor: "#fcbe03",
              }}
              label="Aguardando Aprovação"
            />
          ) : null}
          {user.acesso === "APPROVED" &&
          (permissao === "CREATE_COURSE" || permissao === "DENIED") ? (
            <Chip
              variant="outlined"
              style={{
                marginTop: 10,
                color: "#04b51e",
                borderColor: "#04b51e",
              }}
              label="Aprovado"
            />
          ) : null}
          {user.acesso === "BLOCKED" ? (
            <Chip
              variant="outlined"
              style={{
                marginTop: 10,
                color: "#d62a1e",
                borderColor: "#d62a1e",
              }}
              label="Bloqueado"
            />
          ) : null}
          {(user.acesso === "APPROVED" || user.acesso === "ADMIN") &&
          permissao === "DISABLED" ? (
            <Chip
              variant="outlined"
              style={{
                marginTop: 10,
                color: "#d62a1e",
                borderColor: "#d62a1e",
              }}
              label="Classroom Desabilitado"
            />
          ) : null}
          <Divider style={{ width: "100%", marginTop: 15 }} />
        </div>
      ) : null}
      <List
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <ListItem
            button
            onClick={() => {
              setDrawerToggle();
              history.push("/");
            }}
          >
            <ListItemIcon>
              <Home color="primary" />
            </ListItemIcon>
            <ListItemText primary="Página Inicial" />
          </ListItem>
          {user ? (
            <div>
              {user.acesso === "SUPER_ADMIN" || user.acesso === "ADMIN" ? (
                <div>
                  <ListItem
                    button
                    onClick={() => {
                      setCollapse(!collapse);
                    }}
                  >
                    <ListItemIcon>
                      <Security color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Gestão" />
                    {collapse ? (
                      <ExpandLess color="primary" />
                    ) : (
                      <ExpandMore color="primary" />
                    )}
                  </ListItem>
                  <Collapse in={collapse}>
                    <List dense={true}>
                      {user.acesso === "SUPER_ADMIN" ? (
                        <ListItem
                          button
                          onClick={() => {
                            setDrawerToggle();
                            history.push("/admins");
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 15,
                            }}
                          >
                            <ListItemIcon>
                              <Business color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Administradores" />
                          </div>
                        </ListItem>
                      ) : null}
                      <ListItem
                        button
                        onClick={() => {
                          setDrawerToggle();
                          history.push("/dominios");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 15,
                          }}
                        >
                          <ListItemIcon>
                            <AccountTree color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Domínios" />
                        </div>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          setDrawerToggle();
                          history.push("/usuarios");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 15,
                          }}
                        >
                          <ListItemIcon>
                            <People color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Usuários" />
                        </div>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          setDrawerToggle();
                          history.push("/registros");
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 15,
                          }}
                        >
                          <ListItemIcon>
                            <Equalizer color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Registros" />
                        </div>
                      </ListItem>
                    </List>
                  </Collapse>
                </div>
              ) : null}
              {user.acesso !== "BLOCKED" &&
              user.acesso !== "PENDING" &&
              permissao !== "DISABLED" &&
              permissao !== "" ? (
                <ListItem
                  button
                  onClick={() => {
                    setDrawerToggle();
                    history.push("/copiarconteudo");
                  }}
                >
                  <ListItemIcon>
                    <Publish color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Copiar Conteúdo" />
                </ListItem>
              ) : null}
              <ListItem
                button
                onClick={() => {
                  setDrawerToggle();
                  history.push("/politicaprivacidade");
                }}
              >
                <ListItemIcon>
                  <Help color="primary" />
                </ListItemIcon>
                <ListItemText primary="Política de Privacidade" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setDrawerToggle();
                  history.push("/termos");
                }}
              >
                <ListItemIcon>
                  <AccountBalance color="primary" />
                </ListItemIcon>
                <ListItemText primary="Termos de Serviço" />
              </ListItem>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <ListItem
                button
                onClick={() => {
                  setDrawerToggle();
                  history.push("/logout");
                }}
              >
                <ListItemIcon>
                  <Exit color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
            </div>
          ) : (
            <div>
              <ListItem
                button
                onClick={() => {
                  setDrawerToggle();
                  history.push("/politicaprivacidade");
                }}
              >
                <ListItemIcon>
                  <Help color="primary" />
                </ListItemIcon>
                <ListItemText primary="Política de Privacidade" />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  setDrawerToggle();
                  history.push("/termos");
                }}
              >
                <ListItemIcon>
                  <AccountBalance color="primary" />
                </ListItemIcon>
                <ListItemText primary="Termos de Serviço" />
              </ListItem>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <ListItem style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDrawerToggle();
                    history.push("/login");
                  }}
                  style={{
                    textTransform: "inherit",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingRight: "8px",
                    paddingLeft: "8px",
                    height: "40px",
                    // width: "220px",
                  }}
                >
                  <img
                    style={{
                      height: "18px",
                      width: "18px",
                      marginRight: "24px",
                    }}
                    src={GoogleLogo}
                  />
                  <Typography style={{ fontSize: "14px", color: "#757575" }}>
                    <b>Login com o Google</b>
                  </Typography>
                </Button>
              </ListItem>
            </div>
          )}
        </div>
        <Divider style={{ marginTop: 10 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 15,
              marginBottom: 20,
            }}
          >
            <img
              src={FRM}
              alt="Fundação Roberto Marinho"
              style={{ width: 120 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography style={{ marginTop: 30 }} variant="overline">
              Powered by
            </Typography>
            <img src={GetEdu} alt="GetEdu" style={{ width: 120 }} />
          </div>
        </div>
      </List>
    </div>
  );

  return (
    <div>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ elevation: 5 }}
          variant="permanent"
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ elevation: 5 }}
          variant="temporary"
          open={drawerToggle}
          onClose={() => setDrawerToggle()}
          onOpen={() => setDrawerToggle()}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
}
