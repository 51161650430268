import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context as UserContext } from "../contexts/UserContext";
import { useCookies } from "react-cookie";
import { config } from "../urls";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Block from "@material-ui/icons/Block";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import Sync from "@material-ui/icons/Sync";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    marginTop: 70,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 280,
    },
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function Admin() {
  const { state: user } = useContext(UserContext);
  const [cookie, setCookie] = useCookies();
  const [users, setUsers] = useState([]);
  const [searchSelect, setSearchSelect] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [entrou, setEntrou] = useState(true);
  const [atualizar, setAtualizar] = useState(false);
  const [errado, setErrado] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  const [processando, setProcessando] = useState(false);
  const [tipoAdmin, setTipoAdmin] = useState("SUPER_ADMIN");
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user.acesso !== "SUPER_ADMIN") {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    if (processando === false || atualizar === true) {
      axios
        .get(config.API_URL + "/api/admins", {
          headers: { Authorization: "Bearer " + cookie.token },
        })
        .then((response) => {
          let users = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].email !== user.email) {
              users.push(response.data[i]);
            }
          }
          setUsers(users);
          setAtualizar(false);
          setEntrou(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [processando, atualizar]);

  const customChip = (value) => {
    if (value === "SUPER_ADMIN") {
      return (
        <Chip
          variant="outlined"
          style={{
            color: "black",
            borderColor: "black",
          }}
          label="Super Administrador"
        />
      );
    }
    if (value === "ADMIN") {
      return (
        <Chip
          variant="outlined"
          style={{
            color: "#ababab",
            borderColor: "#ababab",
          }}
          label="Administrador"
        />
      );
    }
  };

  const options = {
    filterType: "checkbox",
    sortOrder: { name: "name", direction: "asc" },
    responsive: "simple",
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 30, 50, 100],
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Atualizar">
            <IconButton
              onClick={() => {
                setEntrou(true);
                setAtualizar(true);
              }}
            >
              <Sync className={classes.icon} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Adicionar Administrador">
            <IconButton onClick={() => setDialog(true)}>
              <Add className={classes.icon} />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      let users = [];
      for (let i = 0; i < selectedRows.data.length; i++) {
        let index = selectedRows.data[i].index;
        users.push(displayData[index].data[2]);
      }
      return (
        <div style={{ display: "flex", marginRight: 10 }}>
          <Dialog
            open={dialog2}
            onClose={() => {
              setDialog2(false);
            }}
          >
            <DialogTitle>Alterar permissão</DialogTitle>
            <DialogContent style={{ minWidth: 300 }}>
              <DialogContentText>Escolha o tipo:</DialogContentText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Radio
                  onClick={() => setTipoAdmin("SUPER_ADMIN")}
                  checked={tipoAdmin === "SUPER_ADMIN"}
                />
                <Chip
                  variant="outlined"
                  style={{
                    color: "black",
                    borderColor: "black",
                  }}
                  label={"Super Administrador"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Radio
                  onClick={() => setTipoAdmin("ADMIN")}
                  checked={tipoAdmin === "ADMIN"}
                />
                <Chip
                  variant="outlined"
                  style={{
                    color: "#ababab",
                    borderColor: "#ababab",
                  }}
                  label="Administrador"
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialog2(false)}>Cancelar</Button>
              <Button
                onClick={() => {
                  setDialog2(false);
                  setProcessando(true);
                  axios
                    .post(
                      config.API_URL + "/api/admins",
                      { users: users, acesso: tipoAdmin },
                      { headers: { Authorization: "Bearer " + cookie.token } }
                    )
                    .then((response) => {
                      setSelectedRows([]);
                      setProcessando(false);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  setSearchInput("");
                }}
              >
                Alterar
              </Button>
            </DialogActions>
          </Dialog>
          <Tooltip title="Alterar Permissão">
            <IconButton
              onClick={() => {
                setDialog2(true);
              }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "lightgrey",
              marginRight: 10,
              marginLeft: 10,
            }}
          ></div>
          <Tooltip title="Remover Permissão">
            <IconButton
              onClick={() => {
                setProcessando(true);
                axios
                  .post(
                    config.API_URL + "/api/admins",
                    { users: users, acesso: "APPROVED" },
                    { headers: { Authorization: "Bearer " + cookie.token } }
                  )
                  .then((response) => {
                    setSelectedRows([]);
                    setProcessando(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              <Block style={{ color: "#d62a1e" }} />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
    setFilterChipProps: (colIndex, colName, filterValue) => {
      if (filterValue === "SUPER_ADMIN") {
        return {
          variant: "outlined",
          style: {
            color: "black",
            borderColor: "black",
            marginTop: 12,
            marginBottom: 10,
          },
          label: "Super Administrador",
        };
      }
      if (filterValue === "ADMIN") {
        return {
          variant: "outlined",
          style: {
            color: "#6e6b6a",
            borderColor: "#6e6b6a",
            marginTop: 12,
            marginBottom: 10,
          },
          label: "Administrador",
        };
      }
    },
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro encontrado",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Limpar",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionadas",
        delete: "Remover",
        deleteAria: "Remover Linhas Selecionadas",
      },
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    setRowProps: () => {
      return {
        selected: false,
      };
    },
  };

  const columns = [
    {
      name: "photo",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "name",
      label: "Nome",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let photo = tableMeta.rowData[0];
          let name = tableMeta.rowData[1];
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar alt={name} src={photo} style={{ marginRight: 15 }} />
              {name}
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "acesso",
      label: "Acesso",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return customChip(value);
        },
        filterOptions: {
          renderValue: (value) => {
            return customChip(value);
          },
        },
      },
    },
    {
      name: "lastLogin",
      label: "Último Login",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== undefined) {
            return new Date(value).toLocaleString();
          } else {
            return "Nunca Logou";
          }
        },
        filter: false,
      },
    },
  ];

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 500 }}
        open={processando || entrou}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <MUIDataTable
        title={"Administradores"}
        columns={columns}
        data={users}
        options={options}
      />
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(false);
          setSearchResult([]);
        }}
      >
        <DialogTitle>Adicionar administrador</DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <DialogContentText>Escolha o tipo:</DialogContentText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Radio
              onClick={() => setTipoAdmin("SUPER_ADMIN")}
              checked={tipoAdmin === "SUPER_ADMIN"}
            />
            <Chip
              variant="outlined"
              style={{
                color: "black",
                borderColor: "black",
              }}
              label={"Super Administrador"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <Radio
              onClick={() => setTipoAdmin("ADMIN")}
              checked={tipoAdmin === "ADMIN"}
            />
            <Chip
              variant="outlined"
              style={{
                color: "#ababab",
                borderColor: "#ababab",
              }}
              label="Administrador"
            />
          </div>
          <DialogContentText>Digite o email do usuário:</DialogContentText>
          <Autocomplete
            freeSolo
            value={searchSelect}
            onChange={(event, newValue) => {
              setSearchSelect(newValue);
            }}
            getOptionLabel={(option) =>
              option.email !== undefined ? option.email : ""
            }
            getOptionSelected={(option, value) => {
              if (value !== "") {
                if (option.email === value.email) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }}
            inputValue={searchInput}
            onInputChange={(event, newValue) => {
              setSearchInput(newValue);
              let teste = /^((([a-z0-9]+[\.\-])*[a-z0-9]+)+@((([a-z0-9]+[\.\-])*[a-z0-9]+)+\.[a-z0-9]+)+)$/.exec(
                newValue
              );
              if (teste === null) {
                setErrado(true);
              } else if (teste !== null && teste.index !== 0) {
                setErrado(true);
              } else {
                setErrado(false);
              }
              if (newValue !== "") {
                axios
                  .post(
                    config.API_URL + "/api/userssearch",
                    { search: newValue },
                    { headers: { Authorization: "Bearer " + cookie.token } }
                  )
                  .then((response) => {
                    setSearchResult(response.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                setSearchResult([]);
              }
            }}
            options={searchResult}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={errado}
                  autoFocus
                  fullWidth
                  label="Usuário"
                />
              );
            }}
            renderOption={(option) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar src={option.photo} alt={option.name} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Typography variant="subtitle1">{option.name}</Typography>
                    <Typography variant="subtitle2">{option.email}</Typography>
                  </div>
                </div>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Cancelar</Button>
          <Button
            disabled={searchInput === "" || errado === true ? true : false}
            onClick={() => {
              setDialog(false);
              setProcessando(true);
              axios
                .post(
                  config.API_URL + "/api/admins",
                  { users: [searchInput], acesso: tipoAdmin },
                  { headers: { Authorization: "Bearer " + cookie.token } }
                )
                .then((response) => {
                  setProcessando(false);
                })
                .catch((error) => {
                  console.log(error);
                });
              setSearchInput("");
            }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
