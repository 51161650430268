import React from "react";
import { Typography, Paper } from "@material-ui/core";

const Home = () => {
  return (
    <div>
      <Paper
        elevation={4}
        style={{ display: "flex", flexDirection: "column", padding: 20 }}
      >
        <Typography
          variant="h4"
          color="secondary"
          style={{ alignSelf: "center", marginBottom: 30 }}
        >
          <b>Seja bem-vindo!</b>
        </Typography>
        <Typography component="div" variant="h5" style={{ color: "grey" }}>
          A Fundação Roberto Marinho firmou parceria com Google for Education
          para oferecer conteúdos educacionais de qualidade, para escolas
          públicas em todo o país.
          <br />
          <br />
          Parte do acervo pedagógico da Fundação Roberto Marinho, com foco no
          Ensino Fundamental II e no Ensino Médio, em consonância com a Base
          Nacional Comum Curricular, está disponível, gratuitamente no Google
          Classroom, da plataforma GSuite for Education, presente em milhares de
          escolas em todo o país.
          <br />
          <br />
          Todo este material pode ser acessado pelas escolas e secretarias de
          Educação que já utilizam a ferramenta através desse site. Efetue login
          neste aplicativo, utilizando o e-mail de sua instituição, para ter
          acesso a todo material disponível para o Classroom de sua instituição!
        </Typography>
        <Typography color="primary" variant="h5" style={{ marginTop: 30 }}>
          <b>Como usar o aplicativo:</b>
        </Typography>
        <Typography
          variant="h6"
          style={{ marginTop: 30, marginLeft: 20, color: "grey" }}
        >
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>1-</b>
            </div>
            <div>
              Para ter acesso aos conteúdos da FRM, faça login com o Google
              utilizando o endereço de e-mail que você usa para acessar suas
              turmas no Classroom e aguarde a autorização caso sua instituição
              não esteja pré-aprovada
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>2-</b>
            </div>
            <div>
              Serão solicitadas autorizações de acesso ao Google Classroom e ao
              Google Drive pelo aplicativo durante o login para que as salas e
              conteúdos possam ser criados durante a replicação de uma sala.
              Nenhuma informação sua será compartilhada com terceiros durante
              esse processo
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>3-</b>
            </div>
            <div>
              As autorizações de acesso no sistema serão feitas em 24 horas.
              Caso já esteja autorizado a usar o aplicativo, siga para o passo 4
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>4-</b>
            </div>
            <div>Selecione no menu ao lado a opção "Copiar Conteúdo"</div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>5-</b>
            </div>
            <div>
              Selecione uma turma para ser copiada e clique no botão
              "Prosseguir"
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>6-</b>
            </div>
            <div>
              Escolha entre criar uma nova sala (caso esteja autorizado pela sua
              instituição) ou copiar o conteúdo em um sala na qual você seja
              professor
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>7-</b>
            </div>
            <div>Personalize o nome da turma, caso esteja criando uma nova</div>
          </div>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div style={{ marginRight: 10 }}>
              <b>8-</b>
            </div>
            <div>Clique no botão "Iniciar"</div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 10 }}>
              <b>9-</b>
            </div>
            <div>
              Aguarde até que o processo seja finalizado. Caso queira, reinicie
              o processo selecionando outra sala
            </div>
          </div>
        </Typography>
      </Paper>
    </div>
  );
};

export default Home;
