import React from "react";
import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import { Context as DrawerContext } from "../contexts/DrawerContext";
import { Context as UserContext } from "../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#0d47a1",
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

export default () => {
  const classes = useStyles();
  const { setDrawerToggle } = useContext(DrawerContext);
  const { state: user } = useContext(UserContext);

  return (
    <div>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar style={{ height: 70, paddingLeft: 10 }}>
          <Hidden mdUp>
            <IconButton
              onClick={() => {
                setDrawerToggle();
              }}
              color="inherit"
            >
              <Menu />
            </IconButton>
          </Hidden>
          <Typography style={{ marginLeft: 10 }} variant="h6">
            Replicação de Conteúdo
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};
