import createDataContext from "./createDataContext";
import axios from "axios";
import { config } from "../urls";

const permissaoReducer = (state, action) => {
  switch (action.type) {
    case "setPermissao":
      return action.payload;
    default:
      return state;
  }
};

const getPermissao = (dispatch) => {
  return (token) => {
    if (token) {
      axios
        .get(config.API_URL + "/api/classroompermissao", {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          dispatch({ type: "setPermissao", payload: response.data });
        })
        .catch((err) => {
          if (err) {
            dispatch({ type: "setPermissao", payload: "DISABLED" });
          }
        });
    } else {
      dispatch({ type: "setPermissao", payload: "DISABLED" });
    }
  };
};

export const { Context, Provider } = createDataContext(
  permissaoReducer,
  { getPermissao },
  ""
);
