import createDataContext from "./createDataContext";

const drawerReducer = (state, action) => {
  switch (action.type) {
    case "setDrawerToggle":
      return !state;
    default:
      return state;
  }
};

const setDrawerToggle = (dispatch) => {
  return () => {
    dispatch({ type: "setDrawerToggle" });
  };
};

export const { Context, Provider } = createDataContext(
  drawerReducer,
  { setDrawerToggle },
  false
);
