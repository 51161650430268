import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useCookies } from "react-cookie";
import { Person, Class, FolderShared } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default () => {
  const theme = useTheme();
  const [cookie, setCookie] = useCookies();
  return (
    <Paper
      elevation={4}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
      }}
    >
      <Typography variant="h3" color="primary">
        Política de Privacidade do Aplicativo
      </Typography>
      <Typography
        variant="h4"
        color="secondary"
        style={{ marginTop: 20, marginLeft: 30 }}
      >
        Informações que coletamos:
      </Typography>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 60,
        }}
      >
        <Person style={{ marginRight: 10 }} /> Perfil do Google
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20, marginLeft: 100 }}>
        - Informações básicas de perfil públicas do usuário, como <b>email</b>,{" "}
        <b>nome</b> e <b>foto</b>.
      </Typography>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 60,
        }}
      >
        <Class style={{ marginRight: 10 }} /> Google Classroom
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20, marginLeft: 100 }}>
        - <b>Perfil</b> do usuário no Google Classroom, especificamente a
        informação de <b>permissão de uso da ferramenta</b> e{" "}
        <b>permissão de criação</b> de novas salas;
        <br />- <b>Acesso</b> à todas as <b>Salas de Aula</b> onde o usuário
        está atualmente atuando como <b>professor</b>.
      </Typography>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 60,
        }}
      >
        <FolderShared style={{ marginRight: 10 }} />
        Google Drive
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20, marginLeft: 100 }}>
        - <b>Acesso à pasta do Drive da Sala de Aula alvo</b> da replicação de
        conteúdo.
      </Typography>
      <Typography
        variant="h4"
        color="secondary"
        style={{ marginTop: 40, marginLeft: 30 }}
      >
        Como usamos essas informações:
      </Typography>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 60,
        }}
      >
        <Person style={{ marginRight: 10 }} /> Perfil do Google
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20, marginLeft: 100 }}>
        - Usamos a informação de perfil para <b>autenticação</b> no site usando
        método OAuth; <br />- Usamos a foto e o nome para <b>personalizar</b> a
        página do usuário.
      </Typography>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 60,
        }}
      >
        <Class style={{ marginRight: 10 }} /> Google Classroom
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20, marginLeft: 100 }}>
        - Usamos o perfil do usuário no Google Classroom para determinar se este{" "}
        <b>pode ou não criar uma nova Sala de Aula</b> antes da replicação de
        conteúdo;
        <br />- Usamos o acesso ao Google Classroom para{" "}
        <b>criar uma nova Sala de Aula</b>, caso o usuário deseje;
        <br />- Usamos o acesso à Sala de Aula do professor para que seja
        possível a <b>criação de tópicos e atividades</b>.
      </Typography>
      <Typography
        variant="h6"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginLeft: 60,
        }}
      >
        <FolderShared style={{ marginRight: 10 }} />
        Google Drive
      </Typography>
      <Typography variant="body1" style={{ marginTop: 20, marginLeft: 100 }}>
        - Usamos o acesso à pasta do Drive da Sala de Aula alvo da replicação
        para <b>criar uma cópia de nossos formulários</b> do Google Forms da
        matéria escolhida.
      </Typography>
    </Paper>
  );
};
