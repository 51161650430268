import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { config } from "./urls";
import { useCookies } from "react-cookie";
import AppBar from "./components/AppBar";
import NavDrawer from "./components/NavDrawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { Context as UserContext } from "./contexts/UserContext";
import { Context as PermissionContext } from "./contexts/PermissaoContext";
import { Context as SocketContext } from "./contexts/SocketContext";
import socketIOClient from "socket.io-client";
import CopiarConteudo from "./pages/CopiarConteudo";
import Usuarios from "./pages/Usuarios";
import Administradores from "./pages/Administradores";
import Dominios from "./pages/Dominios";
import Registros from "./pages/Registros";
import Home from "./pages/Home";
import Termos from "./pages/Termos";
import Privacidade from "./pages/Privacidade";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundImage: `url(${require("./images/google.png")})`,
    backgroundPosition: "right bottom",
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
    backgroundAttachment: "fixed",
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    marginTop: 85,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
    [theme.breakpoints.up("md")]: {
      marginLeft: 300,
    },
  },
  backdrop: {
    marginTop: 70,
    [theme.breakpoints.up("md")]: {
      marginLeft: 280,
    },
  },
}));

if (config.API_URL === "") {
  var ENDPOINT = "/";
} else {
  ENDPOINT = config.API_URL;
}

function App() {
  const [cookie, setCookie] = useCookies();
  const { getUser } = useContext(UserContext);
  const { getPermissao } = useContext(PermissionContext);
  const { setMensagem, setProcessando } = useContext(SocketContext);

  const renewToken = () => {
    setCookie("token", cookie.token, {
      path: "/",
      secure: true,
      maxAge: 864000,
    });
  };

  useEffect(() => {
    if (cookie.token) {
      renewToken();
    }
    getUser(cookie.token);
    getPermissao(cookie.token);
  }, [cookie.token]);

  useEffect(() => {
    if (cookie.token) {
      const socket = socketIOClient(ENDPOINT);
      socket.on("progresso", (data) => {
        setMensagem(data.mensagem);
        setProcessando(data.processando);
      });
      return () => socket.disconnect();
    }
  }, [cookie.token]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Router>
          <AppBar />
          <NavDrawer />
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/usuarios">
              <Usuarios />
            </Route>
            <Route exact path="/copiarconteudo">
              <CopiarConteudo />
            </Route>
            <Route exact path="/auth/google/redirect">
              <Redirect />
            </Route>
            <Route exact path="/admins">
              <Administradores />
            </Route>
            <Route exact path="/dominios">
              <Dominios />
            </Route>
            <Route exact path="/registros">
              <Registros />
            </Route>
            <Route exact path="/politicaprivacidade">
              <Privacidade />
            </Route>
            <Route exact path="/termos">
              <Termos />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

function Login() {
  const classes = useStyles();
  const [processando, setProcessando] = useState(true);
  useEffect(() => {
    axios.get(config.API_URL + "/api/auth/google/login").then((response) => {
      window.location.href = response.data;
    });
  }, []);
  return (
    <Backdrop
      className={classes.backdrop}
      style={{ zIndex: 500 }}
      open={processando}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

function Logout() {
  const [cookie, setCookie, removeCookie] = useCookies();
  const history = useHistory();
  useEffect(() => {
    removeCookie("token", { path: "/" });
    history.push("/");
  }, []);
  return null;
}

function Redirect() {
  const history = useHistory();
  const [cookie, setCookie] = useCookies();
  const query = new URLSearchParams(useLocation().search);
  const authCode = query.get("code");
  const [processando, setProcessando] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    axios
      .post(config.API_URL + "/api/auth/google/redirect", {
        authCode: authCode,
      })
      .then((response) => {
        setCookie("token", response.data, {
          path: "/",
          secure: true,
          maxAge: 864000,
        });
        setProcessando(false);
      })
      .then(() => {
        history.push("/");
      });
  }, [authCode, history, setCookie]);

  return (
    <Backdrop
      className={classes.backdrop}
      style={{ zIndex: 500 }}
      open={processando}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

export default App;
