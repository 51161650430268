import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { config } from "../urls";
import { useCookies } from "react-cookie";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import { Alert, AlertTitle } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import Hidden from "@material-ui/core/Hidden";
import graduation from "../images/graduation.png";
import { Context as UserContext } from "../contexts/UserContext";
import { Context as PermissaoContext } from "../contexts/PermissaoContext";
import { Context as SocketContext } from "../contexts/SocketContext";

export default function CopiarConteudo() {
  const [cookie, setCookie] = useCookies();
  const [titulo, setTitulo] = useState("");
  const [salaId, setSalaId] = useState("");
  const [secao, setSecao] = useState("");
  const [courseState, setCourseState] = useState("ACTIVE");
  const [novaSala, setNovaSala] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [salas, setSalas] = useState("");
  const [entrou, setEntrou] = useState(true);
  const { state: user } = useContext(UserContext);
  const { state: permissao } = useContext(PermissaoContext);
  const {
    state: { salaReplicar, mensagem, processando },
    setMensagem,
    setProcessando,
    setSalaReplicar,
  } = useContext(SocketContext);
  const buttonRef = useRef(null);
  const history = useHistory();

  const salasEF = [
    "FRM - EF - Inglês",
    "FRM - EF - Ciências",
    "FRM - EF - Geografia",
    "FRM - EF - História",
    "FRM - EF - Língua Portuguesa",
    "FRM - EF - Matemática",
  ];

  const salasEM = [
    "FRM - EM - Matemática",
    "FRM - EM - Língua Portuguesa",
    "FRM - EM - História",
    "FRM - EM - Geografia",
    "FRM - EM - Biologia",
    "FRM - EM - Inglês",
    "FRM - EM - Espanhol",
    "FRM - EM - Química",
    "FRM - EM - Física",
    "FRM - EM - Música",
    "FRM - EM - Teatro",
    "FRM - EM - Filosofia",
    "FRM - EM - Sociologia",
    "FRM - EM - Artes",
  ];

  useEffect(() => {
    if (user.email !== undefined) {
      var dominio = user.email.split("@")[1];
      if (dominio === "gmail.com") {
        setCourseState(null);
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      user.acesso === "BLOCKED" ||
      user.acesso === "PENDING" ||
      permissao === "DISABLED" ||
      permissao === ""
    ) {
      history.push("/");
    }
  }, [user, permissao]);

  useEffect(() => {
    if (entrou !== true) {
      buttonRef.current.focus();
    }
  }, [salaReplicar]);

  useEffect(() => {
    setEntrou(false);
    axios
      .get(config.API_URL + "/api/salas", {
        headers: { Authorization: "Bearer " + cookie.token },
      })
      .then((response) => {
        setSalas(response.data);
      })
      .catch((err) => {
        if (err) {
          setSalas("");
        }
      });
  }, []);

  useEffect(() => {
    if (processando === "init") {
      const body = {
        courseId: salaId,
        sala: salaReplicar,
        name: titulo,
        section: secao,
        courseState: courseState,
      };
      axios
        .post(config.API_URL + "/api/copiarconteudo", body, {
          headers: { Authorization: "Bearer " + cookie.token },
        })
        .then((response) => {
          setProcessando("follow");
          if (salaId === "") {
            setMensagem("Criando sala no Google Classroom...");
          } else {
            setMensagem("Iniciando processamento...");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [processando]);

  if (processando === "") {
    return (
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 15,
        }}
        elevation={4}
      >
        <Typography variant="h4">Conteúdo para Copiar:</Typography>
        <Divider style={{ marginTop: 10 }} />
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            paddingLeft: 10,
            paddingRight: 10,
            flexWrap: "wrap",
          }}
        >
          <List
            style={{
              marginTop: 15,
              flexGrow: 1,
            }}
          >
            <Typography style={{ marginBottom: 15 }} variant="h5">
              Ensino Médio:
            </Typography>
            <Divider />
            {salasEM.map((sala) => {
              return (
                <div key={sala}>
                  <ListItem
                    dense={true}
                    button
                    onClick={() => {
                      setSalaReplicar(sala);
                      setTitulo(sala);
                    }}
                  >
                    <ListItemIcon>
                      <Radio checked={salaReplicar === sala} />
                    </ListItemIcon>
                    <ListItemText>{sala}</ListItemText>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </List>
          <Hidden xsDown>
            <div
              style={{
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#dedede",
                marginRight: 30,
                marginLeft: 30,
                marginTop: 15,
              }}
            />
          </Hidden>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              flexWrap: "wrap",
            }}
          >
            <List
              style={{
                marginTop: 15,
              }}
            >
              <Typography style={{ marginBottom: 15 }} variant="h5">
                Ensino Fundamental:
              </Typography>
              <Divider />
              {salasEF.map((sala) => {
                return (
                  <div key={sala}>
                    <ListItem
                      dense={true}
                      button
                      onClick={() => {
                        setSalaReplicar(sala);
                        setTitulo(sala);
                      }}
                    >
                      <ListItemIcon>
                        <Radio checked={salaReplicar === sala} />
                      </ListItemIcon>
                      <ListItemText>{sala}</ListItemText>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
            <Button
              ref={buttonRef}
              variant="contained"
              color="primary"
              style={{
                marginTop: 20,
                marginLeft: 60,
                marginRight: 60,
              }}
              onClick={() => {
                setDialog(true);
              }}
              disabled={salaReplicar === "" ? true : false}
            >
              Prosseguir
            </Button>
            <img
              src={graduation}
              style={{
                alignSelf: "center",
                marginTop: 20,
                marginLeft: 20,
                height: 230,
                width: 250,
              }}
            />
          </div>
        </div>
        {permissao !== "DENIED" || salas.courses !== undefined ? (
          <Dialog
            open={dialog}
            onClose={() => {
              setDialog(false);
              setTitulo(salaReplicar);
              setSecao("");
              setSalaId("");
            }}
          >
            <DialogTitle>Copiar conteúdo em uma:</DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                {permissao !== "DENIED" ? (
                  <Button onClick={() => setNovaSala(true)}>
                    <Radio checked={novaSala === true} />
                    Nova Sala
                  </Button>
                ) : null}
                {salas.courses !== undefined ? (
                  <Button onClick={() => setNovaSala(false)}>
                    <Radio checked={novaSala === false} />
                    Sala Existente
                  </Button>
                ) : null}
              </div>
              {novaSala ? (
                <div>
                  <TextField
                    autoFocus
                    defaultValue={titulo}
                    onChange={(event) => setTitulo(event.target.value)}
                    margin="dense"
                    label="Título"
                    type="text"
                    fullWidth
                    required={titulo === "" ? true : false}
                    error={titulo === "" ? true : false}
                  />
                  <TextField
                    style={{ marginTop: 15 }}
                    defaultValue={secao}
                    onChange={(event) => setSecao(event.target.value)}
                    margin="dense"
                    label="Subtítulo"
                    type="text"
                    fullWidth
                  />
                </div>
              ) : (
                <TextField
                  select
                  label="Escolha uma sala"
                  value={salaId}
                  onChange={(event) => setSalaId(event.target.value)}
                  fullWidth
                >
                  {salas.courses.map((sala) => (
                    <MenuItem key={sala.id} value={sala.id}>
                      {sala.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDialog(false);
                  setTitulo(salaReplicar);
                  setSecao("");
                  setSalaId("");
                }}
                color="primary"
              >
                Cancelar
              </Button>
              <Button
                disabled={
                  (titulo === "" && novaSala === true) ||
                  (salaId === "" && novaSala === false)
                    ? true
                    : false
                }
                onClick={() => {
                  setDialog(false);
                  setProcessando("init");
                }}
                color="primary"
              >
                Iniciar
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={dialog}
            onClose={() => {
              setDialog(false);
            }}
          >
            <DialogTitle>Atenção!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você precisa estar em uma sala como professor ou possuir
                permissão para criação de salas para replicar conteúdo. Converse
                com o seu gestor acadêmico para mais informações.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialog(false)}>Ok</Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    );
  } else {
    if (processando === "follow" || processando === "init") {
      return (
        <Paper
          elevation={4}
          style={{
            padding: 15,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Alert severity="info">
            <AlertTitle>
              <strong>Processando...</strong>
            </AlertTitle>
            {mensagem}
          </Alert>
          <Alert style={{ marginTop: 20 }} severity="warning">
            Isso pode demorar um pouco
          </Alert>
        </Paper>
      );
    } else if (processando === "done") {
      return (
        <Paper
          elevation={4}
          style={{
            padding: 15,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Alert severity="success">
            <AlertTitle>
              <strong>Sucesso!</strong>
            </AlertTitle>
            {mensagem}
          </Alert>
          <Button
            variant="contained"
            style={{ marginLeft: 60, marginRight: 60, marginTop: 20 }}
            color="primary"
            onClick={() => {
              setSalaReplicar("");
              setProcessando("");
              setSalaId("");
              setTitulo("");
              setSecao("");
              setNovaSala(true);
            }}
          >
            Escolher outra sala
          </Button>
        </Paper>
      );
    } else if (processando === "error") {
      return (
        <Paper
          elevation={4}
          style={{
            padding: 15,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <Alert severity="error">
            <AlertTitle>
              <strong>Ops, algo deu errado!</strong>
            </AlertTitle>
            {mensagem}
          </Alert>
          <Button
            variant="contained"
            style={{
              marginTop: 20,
              marginLeft: 60,
              marginRight: 60,
            }}
            color="primary"
            onClick={() => {
              setSalaReplicar("");
              setProcessando("");
              setSalaId("");
              setTitulo("");
              setSecao("");
              setNovaSala(true);
            }}
          >
            Tentar novamente
          </Button>
        </Paper>
      );
    }
  }
}
