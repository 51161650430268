import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { useCookies } from "react-cookie";
import { useTheme } from "@material-ui/core/styles";

export default () => {
  const theme = useTheme();
  const [cookie, setCookie] = useCookies();
  return (
    <Paper
      elevation={4}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
      }}
    >
      <Typography variant="h3" color="primary">
        Política de Privacidade e Termos de Uso do Telecurso e conteúdos via
        Google for Education (G Suite)
      </Typography>
      <Typography component="div" variant="body1">
        <br />
        A Fundação Roberto Marinho tem a preocupação com a proteção dos dados
        pessoais de todos aqueles que acessam os conteúdos que disponibilizamos.
        Além disso, temos o dever de tutelar os direitos autorais e outros
        direitos incidentes sobre esses conteúdos, os quais muitas vezes nos são
        licenciados ou autorizados por terceiros. Através da presente Política
        de Privacidade e Termos de Uso esclarecemos as principais questões de
        privacidade e propriedade intelectual no acesso dos conteúdos do
        Telecurso por meio da plataforma Google for Education (G Suite). E
        tornamos público o nosso compromisso com a proteção da privacidade dos
        usuários, dando transparência às eventuais operações de tratamento de
        dados que poderão ser realizadas por nós e ou terceiros, e reiteramos o
        nosso empenho com a tutela dos direitos dos autores, criadores e
        titulares dos conteúdos educacionais que utilizamos. Pedimos a você que
        leia atentamente.
        <br />
        <br />
        <Box color="secondary.main">
          <b>1) A Fundação Roberto Marinho e a nossa missão institucional</b>
        </Box>
        <br />
        A Fundação Roberto Marinho desenvolve projetos voltados para a
        escolaridade básica e para a solução de problemas educacionais que
        impactam nas avaliações nacionais, como: distorção idade-série, evasão
        escolar e a defasagem na aprendizagem. Atua em soluções de educação, com
        foco em correção de fluxo e reforço escolar, e na inclusão de jovens no
        mundo do trabalho.
        <br />
        <br />
        <Box color="secondary.main">
          <b>2) O Telecurso</b>
        </Box>
        <br />
        O Telecurso passou a ser adotado como política pública em parceria com o
        Ministério da Educação, governos estaduais e municipais, em escolas
        públicas de todo país. Desde 1995, 1,6 milhão de estudantes de escolas
        públicas, que estavam fora da escola ou em defasagem idade-série, foram
        formados pelo Telecurso em 12 estados do Brasil. Também continuou a ser
        implementado em ONGs, empresas, associações comunitárias e sindicatos de
        todo o país.
        <br />
        <br />
        <Box color="secondary.main">
          <b>3) Tratamentos de dados no projeto Telecurso</b>
        </Box>
        <br />
        A Fundação Roberto Marinho, sempre que necessário para o desempenho de
        sua missão institucional e para alcançar os objetivos específicos do
        projeto Telecurso, poderá realizar atividades de tratamento de dados
        pessoais, sendo que alguns desses tratamentos poderão ocorrer mediante o
        consentimento do titular, enquanto outros, nas hipóteses em que houver
        permissão legal, ocorrerão independentemente do consentimento.
        <br />
        <br />
        <Box color="secondary.main">
          <b>4) Como serão usados os seus dados pessoais</b>
        </Box>
        <br />
        Todos os dados pessoais coletados serão utilizados para finalidades
        determinadas, relacionadas aos fins institucionais da Fundação Roberto
        Marinho, do projeto Telecurso e ou para viabilizar o fornecimento de
        seus serviços.
        <br />
        <br />
        Essas finalidades, quando alcançadas, levarão ao encerramento da
        atividade de tratamento e a eliminação dos dados pessoais coletados. Os
        dados pessoais só não serão eliminados quando restar configurada
        hipótese legal e ou obrigação legal, administrativa ou contratual
        autorizando ou determinando a sua conservação.
        <br />
        <br />
        Quando for possível a anonimização dos dados, estes poderão ser
        anonimizados e conservados para fins de estudos e pesquisas.
        <br />
        <br />
        <Box color="secondary.main">
          <b>5) Coleta de dados</b>
        </Box>
        <br />
        Todos os dados pessoais coletados serão utilizados para finalidades
        determinadas, relacionadas aos fins institucionais da Fundação Roberto
        Marinho, do projeto Telecurso e ou para viabilizar o fornecimento de
        seus serviços.
        <br />
        <br />
        Ao acessar os sites da Fundação Roberto Marinho e do Telecurso, certas
        informações sobre o usuário serão armazenadas, tais como o protocolo de
        internet (IP), navegador utilizado, sistema operacional, conteúdo
        acessado, tempo de utilização do site, e outras informações semelhantes.
        <br />
        <br />
        O acesso aos sites da Fundação Roberto Marinho poderá implicar na
        instalação ou pedido de autorização para instalação de cookies no
        dispositivo utilizado para o acesso, os quais têm por finalidade a
        coleta de informações relacionadas à atividade de navegação e que
        poderão servir para o aperfeiçoamento do funcionamento do site. Os
        cookies também poderão servir para a coleta e o armazenamento de
        informações sobre consultas e visitas realizadas por cada usuário, com o
        intuito de possibilitar uma melhor experiência para o usuário e a
        realização de melhorias no site e seus conteúdos.
        <br />
        <br />
        A Fundação Roberto Marinho também coleta informações de cunho pessoal
        através da sua inscrição, participação em nossos projetos e atividades,
        através do preenchimento dos formulários, e ou por meio de sua
        Secretaria de Educação e ou estabelecimento de ensino. Esses dados são
        coletados unicamente com o objetivo de alcançar os objetivos específicos
        do projeto Telecurso.
        <br />
        <br />
        <Box color="secondary.main">
          <b>6) Compartilhamento de dados</b>
        </Box>
        <br />
        Como o Telecurso é um projeto educacional realizado em parceria com
        diferentes Secretarias de Educação e estabelecimentos de ensino, a
        Fundação Roberto Marinho poderá compartilhar seus dados com a Secretaria
        de Educação e ou estabelecimentos de ensino responsáveis pela sua
        inscrição no projeto.
        <br />
        <br />
        Também, importante destacar que a plataforma Google for Education (G
        Suite) é fornecida e administrada pela Google LLC, uma empresa privada
        sediada em Mountain View, Califórnia, Estados Unidos, e que alguns dados
        poderão ser compartilhados para permitir o seu acesso e o pleno
        funcionamento da plataforma.
        <br />
        <br />
        Em outros casos, os dados pessoais somente serão fornecidos a terceiros
        na forma da Lei e ou mediante ordem judicial e ou de autoridades
        administrativas.
        <br />
        <br />
        <Box color="secondary.main">
          <b>7) Uso de aplicativos de terceiros</b>
        </Box>
        <br />
        A Fundação Roberto Marinho não se responsabiliza pelos tratamentos de
        dados pessoais efetuados em razão da utilização de sistemas ou
        aplicativos disponibilizados por terceiros, ainda que o acesso possa ter
        ocorrido através do uso de links disponibilizados por nós. O que inclui
        a plataforma Google for Education (G Suite), sistemas de webmail, chat
        online, redes sociais, formulários online, sites e ferramentas de
        armazenamento e compartilhamento de arquivos, entre outros.
        <br />
        <br />
        Também, as páginas de terceiros poderão utilizar cookies. Esses cookies
        são diferentes daqueles utilizados pela Fundação Roberto Marinho e de
        total responsabilidade do titular/proprietário do website.
        <br />
        <br />
        Assim, caberá ao usuário/titular dos dados verificar as condições para
        tratamento de dados indicadas pelas plataformas parceiras da Fundação
        Roberto Marinho ou que venham a ser utilizadas no decorrer do projeto,
        com ou sem a nossa indicação. Ao verificar essas condições o usuário
        deverá ler atentamente as Políticas de Privacidade desses sistemas,
        aplicativos e sites.
        <br />
        <br />
        Por fim, reiteramos e deixamos cientes os usuários de que a Fundação
        Roberto Marinho não possui qualquer responsabilidade sobre esses
        sistemas e ou aplicativos e, portanto, nenhum controle ou ingerência
        sobre os tratamentos de dados pessoais eventualmente realizados por
        eles.
        <br />
        <br />
        <Box color="secondary.main">
          <b>8) Segurança</b>
        </Box>
        <br />
        A Fundação Roberto Marinho adotará níveis de segurança dentro dos
        requisitos legais para a proteção dos dados pessoais. Entretanto, os
        usuários deverão ter ciência de que essa proteção não é infalível, e,
        pela própria natureza e características técnicas da internet,
        encontram-se sujeitas a violações e outras práticas maliciosas.
        <br />
        <br />
        Na eventualidade de ocorrer algum incidente de segurança envolvendo os
        dados pessoais de seus usuários, que acarrete risco ou dano relevante, a
        Fundação Roberto Marinho se compromete a comunicar o ocorrido.
        <br />
        <br />
        <Box color="secondary.main">
          <b>9) Armazenamento de dados</b>
        </Box>
        <br />
        A Fundação Roberto Marinho utiliza servidores próprios para o
        armazenamento de dados pessoais dos usuários, localizados em território
        brasileiro e submetidos às regras previstas na legislação brasileira.
        Nos casos em que os dados precisarem ser armazenados fora do país, como
        em razão de armazenamento e computação em nuvem, será assegurado aos
        dados pessoais grau de proteção idêntico ao que seria dispensado caso
        estivessem armazenados em servidores localizados em território
        brasileiro.
        <br />
        <br />
        <Box color="secondary.main">
          <b>10) Direitos e Deveres do titular dos dados pessoais</b>
        </Box>
        <br />
        A Fundação Roberto Marinho assegura aos usuários a transparência em
        relação aos tratamentos de dados que realiza e faculta aos titulares a
        correção e a atualização dos dados pessoais incorretos, inexatos ou
        desatualizados.
        <br />
        <br />
        Nos casos previstos na legislação, também é assegurada a anonimização,
        bloqueio e ou eliminação dos dados pessoais que se revelem
        desnecessários ou que estejam sendo tratados em desconformidade com a
        Lei.
        <br />
        <br />
        O usuário deverá igualmente agir de maneira colaborativa e transparente,
        comprometendo-se a fornecer, sempre que solicitado, informações exatas e
        corretas, e assume a obrigação de manter os seus dados atualizados.
        <br />
        <br />
        <Box color="secondary.main">
          <b>11) Direitos autorais e de Propriedade intelectual</b>
        </Box>
        <br />
        A plataforma contém material do Telecurso, incluindo texto, fotografias
        e outras imagens e sons que são protegidos por direitos autorais e/ou
        outros direitos de propriedade intelectual. Qualquer direito autoral e
        outros direitos de propriedade intelectual, relacionados ao Telecurso,
        pertencem à Fundação Roberto Marinho ou foram licenciados a ela pelos
        seus autores/titulares para que possam ser usados como parte do material
        Telecurso.
        <br />
        <br />
        Todas as marcas registradas incluídas no material Telecurso pertencem a
        Fundação Roberto Marinho ou foram licenciadas a ela pelos seus
        proprietários/titulares para uso no referido material. É vedado:
        <br />
        <br />
        <div style={{ marginLeft: 30 }}>
          <b>• Copiar</b> (através de impressão em papel, armazenagem em disco,
          de download ou de qualquer outra forma), <b>distribuir</b> (incluindo
          distribuição de cópias), <b>transmitir</b>, <b>alterar</b> ou{" "}
          <b>adulterar</b> de qualquer forma, no todo ou em parte, os materiais
          recebidos ou contidos no Google for Education (G Suite).
          <br />
          <br />
          <b>• Remover</b> quaisquer notificações de direito autoral, de marca
          registrada ou de propriedade intelectual contidos nos materiais
          recebidos ou contidos no Google for Education (G Suite).
        </div>
        <br />
        A reprodução dos conteúdos descritos anteriormente está proibida, salvo
        prévia autorização por escrito da Fundação Roberto Marinho, ou caso se
        destinem a uso exclusivamente pessoal. Em nenhuma circunstância o
        usuário adquirirá qualquer direito sobre os materiais e conteúdos.
        <br />
        <br />
        O usuário assume toda e qualquer responsabilidade, de caráter civil e/ou
        criminal, pela utilização indevida das informações, textos, gráficos,
        marcas, obras, enfim, de todo e qualquer Direito de Propriedade
        Intelectual.
        <br />
        <br />
        <Box color="secondary.main">
          <b>12) Alterações no conteúdo disponibilizado</b>
        </Box>
        <br />
        A Fundação Roberto Marinho poderá mudar o formato e o conteúdo dos
        materiais disponibilizados na plataforma Google for Education (G Suite).
        Também, poderá atualizar os conteúdos, retirar e/ou extinguir o acesso a
        qualquer material, independentemente de notificação prévia.
        <br />
        <br />
        <Box color="secondary.main">
          <b>13) Envio de Notificações</b>
        </Box>
        <br />
        Ao utilizar a plataforma Google for Education (G Suite), o usuário
        permite o envio de notificações sobre o projeto Telecurso, a plataforma
        e/ou materiais ali inseridos.
        <br />
        <br />
        <Box color="secondary.main">
          <b>14) Modificações em nossa Política</b>
        </Box>
        <br />
        Para manter atualizados os seus termos, inclusive frente a modificações
        tecnológicas, de procedimentos e ou metodologias relativas ao projeto
        Telecurso, e legais, a Fundação Roberto Marinho poderá atualizar a
        presente política, sempre que julgar necessário. Assim, recomenda-se que
        os usuários verifiquem periodicamente as eventuais atualizações e
        modificações a presente política.
        <br />
        <br />
        De todo modo, a Fundação Roberto Marinho manterá junto a esta política
        um registro das eventuais alterações e, no caso de modificações que
        possam importar em alteração das finalidades de tratamento de dados, ou
        que importem em compartilhamento ou comunicação de dados a terceiros,
        notificará os titulares na forma prevista na legislação e, quando
        necessário, poderá requerer ao usuário o seu consentimento ou a
        renovação do consentimento prévio.
        <br />
        <br />
        <Box color="secondary.main">
          <b>15) Legislação aplicável e foro</b>
        </Box>
        <br />
        Esta Política será regida, interpretada e executada de acordo com a
        legislação da República Federativa do Brasil, sendo competente o Foro
        Central da Comarca da Capital do Estado do Rio de Janeiro para dirimir
        qualquer dúvida decorrente deste instrumento. O usuário consente,
        expressamente, com a competência desse juízo, e renúncia, neste ato, a
        qualquer outro foro, por mais privilegiado que seja ou venha a ser.
        <br />
        <br />
        <Box color="secondary.main">
          <b>16) Questões, sugestões e dúvidas</b>
        </Box>
        <br />
        Todas as questões, sugestões e dúvidas referentes a essa política e os
        assuntos a ela relacionados deverão ser encaminhadas por e-mail, através
        do seguinte endereço: registro.tecnologia@frm.org.br.
        <br />
        <br />
      </Typography>
    </Paper>
  );
};
