import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context as UserContext } from "../contexts/UserContext";
import { useCookies } from "react-cookie";
import { config } from "../urls";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Sync from "@material-ui/icons/Sync";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from "@material-ui/core/Tooltip";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  backdrop: {
    marginTop: 70,
    [theme.breakpoints.up("md")]: {
      marginLeft: 280,
    },
  },
  snackbar: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
      marginLeft: 30,
      marginRight: 30,
    },
  },
  icon: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function Admin() {
  const { state: user } = useContext(UserContext);
  const [cookie, setCookie] = useCookies();
  const [logs, setLogs] = useState([]);
  const [entrou, setEntrou] = useState(true);
  const [atualizar, setAtualizar] = useState(false);
  const [processando, setProcessando] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user.acesso !== "SUPER_ADMIN" && user.acesso !== "ADMIN") {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    if (processando === false || atualizar === true) {
      axios
        .get(config.API_URL + "/api/logs", {
          headers: { Authorization: "Bearer " + cookie.token },
        })
        .then((response) => {
          setLogs(response.data);
          setAtualizar(false);
          setEntrou(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [processando, atualizar]);

  const options = {
    filterType: "checkbox",
    sortOrder: { name: "date", direction: "desc" },
    responsive: "simple",
    selectableRows: "none",
    rowsPerPage: 15,
    rowsPerPageOptions: [10, 15, 30, 50, 100],
    setFilterChipProps: () => {
      return {
        className: classes.chip,
      };
    },
    customToolbar: () => {
      return (
        <Tooltip title="Atualizar">
          <IconButton
            onClick={() => {
              setEntrou(true);
              setAtualizar(true);
            }}
          >
            <Sync className={classes.icon} />
          </IconButton>
        </Tooltip>
      );
    },
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro encontrado",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Procurar",
        downloadCsv: "Baixar CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Limpar",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionadas",
        delete: "Remover",
        deleteAria: "Remover Linhas Selecionadas",
      },
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    setRowProps: () => {
      return {
        selected: false,
      };
    },
  };

  const columns = [
    {
      name: "origin",
      label: "Autor",
      options: {
        filter: false,
      },
    },
    {
      name: "type",
      label: "Evento",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "CHANGE_USER_PERMISSION") {
            return "Trocou Permissão de Usuário";
          } else if (value === "DELETE_USER") {
            return "Removeu Usuário";
          } else if (value === "CHANGE_DOMAIN_PERMISSION") {
            return "Trocou Permissão de Domínio";
          } else if (value === "COPY_CLASSROOM") {
            return "Copiou Conteúdo";
          }
        },
      },
    },
    {
      name: "value",
      label: "Valor",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "PENDING") {
            return "Pendente";
          } else if (value === "BLOCKED") {
            return "Bloqueado";
          } else if (value === "APPROVED") {
            return "Aprovado";
          } else if (value === "ADMIN") {
            return "Administrador";
          } else if (value === "SUPER_ADMIN") {
            return "Super Administrador";
          } else {
            return value;
          }
        },
        filterOptions: {
          renderValue: (value) => {
            console.log(value);
            if (value !== undefined) {
              return value;
            } else {
              return "Vazio";
            }
          },
        },
      },
    },
    {
      name: "target",
      label: "Alvo",
      options: {
        filter: false,
      },
    },
    {
      name: "date",
      label: "Data",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return new Date(value).toLocaleString();
        },
      },
    },
  ];

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 500 }}
        open={processando || entrou}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <MUIDataTable
        title={"Registros"}
        columns={columns}
        data={logs}
        options={options}
      />
    </div>
  );
}
