import "./index.css";
import "fontsource-roboto";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import * as serviceWorker from "./serviceWorker";
import { Provider as UserProvider } from "./contexts/UserContext";
import { Provider as DrawerProvider } from "./contexts/DrawerContext";
import { Provider as PermissaoProvider } from "./contexts/PermissaoContext";
import { Provider as SocketProvider } from "./contexts/SocketContext";

ReactDOM.render(
  <React.Fragment>
    <SocketProvider>
      <DrawerProvider>
        <UserProvider>
          <PermissaoProvider>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </PermissaoProvider>
        </UserProvider>
      </DrawerProvider>
    </SocketProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
